import { FunctionComponent, useEffect, useState } from 'react';

import { Box, Grid, styled, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

import { PageSlide } from '../PageSlide';

interface Item {
  words: string;
}

const defaultItems: Item[] = [
  {
    words:
      'Waldo is amazing for efficiency on the strategy team. This will cut down on 10-15 hours of time in reports we do each week. Giving the account team the knowledge to answer simple client questions is so useful.',
  },
  {
    words:
      'We get requests for insights all the time it’s great that you can make everything so customizable. You can do a POV in 10 minutes in Waldo. You blew my mind for this. No one has any free time so this is impressive.',
  },
  {
    words:
      'Categorically I’ve leaned on Waldo to get smart fast on a new vertical or a new client. We’ve found awesome stuff that would have taken hours. The brief export has been awesome.',
  },
];

const getGroupedItems = (items: Item[], perGroup = 2): Item[][] => {
  const groups: Item[][] = [];

  items.forEach((item) => {
    const lastIndex = groups.length - 1;

    if (!groups.length || groups[lastIndex].length === perGroup) {
      groups.push([item]);
    } else {
      groups[lastIndex].push(item);
    }
  });

  return groups;
};

const TestimonialsStyled = styled(PageSlide)(() => ({
  backgroundColor: '#D40A60',
}));

const CarouselStyled = styled(Carousel)(() => ({
  width: '100%',
}));

const TWO_COLUMN_MIN_WIDTH = 1050;

export const Testimonials: FunctionComponent = () => {
  const [twoColumn, setTwoColumn] = useState(true);
  const testimonials = getGroupedItems(defaultItems, twoColumn ? 2 : 1);

  useEffect(() => {
    const handleWindowSize = () => {
      setTwoColumn(window.innerWidth >= TWO_COLUMN_MIN_WIDTH);
    };

    // Add event listener
    window.addEventListener('resize', handleWindowSize);

    // Call handler right away so state gets updated with initial window size
    handleWindowSize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleWindowSize);
  }, [setTwoColumn]);

  return (
    <TestimonialsStyled justifyContent="center" minHeight={0} p={5}>
      <CarouselStyled
        autoPlay={false}
        height={twoColumn ? 250 : 400}
        animation="slide"
        activeIndicatorIconButtonProps={{
          className: '',
          style: { color: '#ECFBFB', opacity: 1 },
        }}
        indicatorContainerProps={{
          className: '',
          style: { marginTop: '4rem' },
        }}
        indicatorIconButtonProps={{
          className: '',
          style: { color: '#A7D3D3', opacity: 0.5 },
        }}
        navButtonsAlwaysInvisible
      >
        {testimonials.map((row, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid container justifyContent="center" key={index}>
            {row.map(({ words }, i) => (
              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  maxWidth: '500px !important',
                }}
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                sm={12}
                md={row.length === 1 ? 12 : 6}
              >
                <Box maxWidth="27.5rem">
                  {/* <Box> */}
                  <Box mb={1}>
                    <Typography variant="h6">“{words}”</Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        ))}
      </CarouselStyled>
    </TestimonialsStyled>
  );
};
