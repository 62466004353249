import { FunctionComponent } from 'react';
import { styled, Link, Typography } from '@mui/material';

const CopyrightText = styled(Typography)(({ theme }) => ({
  color: '#ACACAC',
  display: 'inline-block',
  textAlign: 'center',

  '& a': {
    display: 'inline-block',
    margin: theme.spacing(0, 0.5),
  },
}));

export const Copyright: FunctionComponent = () => (
  <CopyrightText variant="caption">
    Copyright {new Date().getFullYear()} - All Rights Reserved Waldo.
    <Link
      href="/app/tos"
      target="_blank"
      color="inherit"
      underline="none"
      variant="caption"
    >
      terms of service
    </Link>
    •
    <Link
      href="/privacy"
      target="_blank"
      color="inherit"
      underline="none"
      variant="caption"
    >
      privacy policy
    </Link>
  </CopyrightText>
);
